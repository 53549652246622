/* FloatingTagWithParticles.module.scss */
.container {
    position: relative;

    .tag {
        background-color: var(--colorV2-light-green);
        color: var(--colorV2-dark-green);
        border-radius: 20px;
        display: inline-block;
        font-weight: bold;
        cursor: default;
    }

    .particle {
        width: 8px;
        height: 8px;
        position: absolute;
        animation: float 8s ease-in-out infinite, blink 5s linear infinite; // Adjusted timing for less frequent blinking
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@keyframes float {
    0%, 100% { transform: translate(0, 0) rotate(0deg); }
    25% { transform: translate(0px, -3px) rotate(-3deg); }
    50% { transform: translate(0px, 2px) rotate(3deg); }
    75% { transform: translate(0px, -3px) rotate(-3deg); }
}

@keyframes blink {
    20%, 50% { opacity: 1; } // Gradual fade in and out
    0%, 70%, 100%  { opacity: 0; }
}
