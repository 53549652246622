@import '@styles/variables';
@import '@styles/mixins';

.mobileView {
    display: none;

    @include mobile-all {
        display: block;
    }
}

.mobilePortraitView {
    display: none;

    @include mobile-portrait {
        display: block;
    }
}

.nonMobilePortraitView {
    display: block;

    @include mobile-portrait {
        display: none;
    }
}

.nonMobileView {
    display: block;

    @include mobile-all {
        display: none;
    }
}

.desktopView {
    display: none;

    @include desktop-all {
        display: block;
    }
}

.smallDesktopView {
    display: none;

    @include desktop-small {
        display: block;
    }
}

.wideDesktopView {
    display: none;

    @include desktop-wide {
        display: block;
    }
}

.nonDesktopView {
    display: block;

    @include desktop-all {
        display: none;
    }
}

.nonWideDesktopView {
    display: block;

    @include desktop-wide {
        display: none;
    }
}
